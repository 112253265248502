import React from 'react'
import styles from './client.module.css'

const Client = ({title, image}) => {
    return(
        <div className={styles.clientContainer}>
            <div className={styles.imageContainer}>
                <img src={image} alt={title} className="swiper-lazy" />
            </div>
        </div>
    )
}

export default Client;