import React from "react"
import Img from "gatsby-image"
import Layout from "../components/layout/layout"
import { Container, Grid, Typography, Paper } from "@material-ui/core"
import styles from "../stylesheets/about.module.css"
import SEO from "../components/seo"
import Client from "../components/client/client"
import Swiper from "react-id-swiper"
import "../stylesheets/swiper.css"
import ScrollAnimation from "react-animate-on-scroll"
import Landing from "../components/landing/landing"

export const query = graphql`
  {
    aboutus: allFile(
      filter: {
        extension: { regex: "/(jpg)|(jpeg)/" }
        sourceInstanceName: { eq: "assets" }
        id: { eq: "edd33c55-0bdb-5714-af6d-f60a06a78912" }
      }
    ) {
      edges {
        node {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    clients: allFile(
      filter: {
        extension: { regex: "/(jpg)|(jpeg)|(png)/" }
        sourceInstanceName: { eq: "clients" }
      }
    ) {
      edges {
        node {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`

const params = {
  slidesPerView: 3,
  spaceBetween: 30,
  loop: true,
  centeredSlides: true,
  autoplay: {
    delay: 2000,
    disableOnInteraction: false,
  },
  breakpoints: {
    1024: {
      slidesPerView: 4,
      spaceBetween: 40,
    },
    768: {
      slidesPerView: 3,
      spaceBetween: 30,
    },
    640: {
      slidesPerView: 2,
      spaceBetween: 20,
    },
    470: {
      slidesPerView: 1,
      spaceBetween: 15,
    },
  },
}

export default ({ data }) => {
  let clientsData = []

  data.clients.edges.map(el => {
    let temp = {}
    temp.image = el.node.childImageSharp.fluid.src
    temp.title = el.node.childImageSharp.fluid.src.split("/")[4]
    temp.title = decodeURI(temp.title.split(".")[0])
    clientsData.push(temp)
    return null
  })

  let clients = null
  clients = clientsData.map(el => (
    <div key={el.title}>
      <Client title={el.title} image={el.image} />
    </div>
  ))

  return (
    <Layout pageTitle="About">
      <SEO title="About" />
      <Landing type="About" />
      <Container className={styles.aboutContainer}>
        <Grid style={{ marginBottom: "80px" }} container>
          <Grid className={styles.gridItem} item sm={12} md={6}>
            <ScrollAnimation offset={20} animateIn="fadeIn" animateOnce>
              <Typography className={styles.aboutHeading} variant="h5">
                ABOUT US
              </Typography>
              <Img
                className={styles.aboutImg}
                fluid={data.aboutus.edges[0].node.childImageSharp.fluid}
              />
            </ScrollAnimation>
          </Grid>
          <Grid item sm={12} md={6}>
            <ScrollAnimation animateIn="fadeIn" animateOnce>
              <Typography className={styles.aboutPara} variant="body2">
                A. Selvaraju Architects was founded in 1995 at Chennai (Thousand
                lights) and has steadily grown for the past 19 years. Our
                Principal Architect Mr. A. Selvaraju has served as an associate
                architect at Ar. Kharche &amp; Associates for more than ten(10)
                years before establishing his own architectural firm, A.
                Selvaraju Architects.
              </Typography>
            </ScrollAnimation>
            <ScrollAnimation animateIn="fadeIn" animateOnce>
              <Typography className={styles.aboutPara} variant="body2">
                Today, we have spread our wings through out the state of
                Tamilnadu and successfully designed ambient environment in
                different sectors, from residential buildings (such as villas
                and bungalows) to commercial edifice (rental, industrial
                factories and hospitality spaces). Some of our prestigious
                clients include Agni Estate and Foundation, Elite construction,
                sugal &amp; damani, VAV Promoters and contractors, Corporation
                of Chennai, Vel’s group of institutions and Asian Beverages.
              </Typography>
            </ScrollAnimation>
            <ScrollAnimation animateIn="fadeIn" animateOnce>
              <Typography className={styles.aboutPara} variant="body2">
                Our clients are an integral part of designing process. We
                constantly interact &amp; exchange ideas with clients in order
                to deliver the most innovative &amp; economical design. Our
                mantra is to provide our clients, “Ambient design for a
                wonderful living experience”.
              </Typography>
            </ScrollAnimation>
            <ScrollAnimation animateIn="fadeIn" animateOnce>
              <Typography className={styles.aboutPara} variant="body2">
                We owe our success to our team of experienced draftsman,
                architect, planners and clients for giving us the opportunity to
                design iconic structures.
              </Typography>
            </ScrollAnimation>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12}>
            <ScrollAnimation offset={50} animateIn="fadeIn" animateOnce>
              <Typography
                style={{ textAlign: "center" }}
                className={styles.aboutHeading}
                variant="h5"
              >
                OUR CLIENTS
              </Typography>
            </ScrollAnimation>
          </Grid>
          <Grid item xs={12} style={{ margin: "25px 0 50px 0" }}>
            <Swiper {...params}>{clients}</Swiper>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12}>
            <Typography
              style={{ textAlign: "center" }}
              className={styles.aboutHeading}
              variant="h5"
            >
              WHAT WE DO
            </Typography>
          </Grid>
          <Grid item xs={12} style={{ marginTop: "25px" }}>
            <Container className={styles.servicesContainer}>
              <Grid container spacing={1}>
                <Grid item xs={12} lg={5}>
                  <div style={{ position: "relative" }}>
                    <Paper className={styles.architect}>
                      <svg>
                        <rect></rect>
                      </svg>
                      <span>Architecture</span>
                      <div className={styles.archOverlay}>
                        <p>Architecture</p>
                      </div>
                    </Paper>
                  </div>
                </Grid>
                <Grid item xs={12} lg={7}>
                  <div style={{ position: "relative" }}>
                    <Paper className={styles.planning}>
                      <svg>
                        <rect></rect>
                      </svg>
                      <span>Planning</span>
                      <div className={styles.planOverlay}>
                        <p>Planning</p>
                      </div>
                    </Paper>
                  </div>
                  <div style={{ position: "relative" }}>
                    <Paper className={styles.design}>
                      <svg>
                        <rect></rect>
                      </svg>
                      <span>Interior Design</span>
                      <div className={styles.desOverlay}>
                        <p>Interior Design</p>
                      </div>
                    </Paper>
                  </div>
                </Grid>
              </Grid>
            </Container>
          </Grid>
        </Grid>
      </Container>
    </Layout>
  )
}
